export const CUSTOMER_ROLE = 1; // 00000001
export const PROVIDER_OD_ROLE = 2; // 00000010
export const AGENT_ND_ROLE = 4; // 00000100
export const AGENT_ADMIN_ROLE = 8; // 00001000
export const ORG_ADMIN_ROLE = 16; // 00010000

export const PLATFORM_ADMIN_ROLE = 128; // 10000000
export const COMMUNITY_MANAGERS_EMAIL_ONLY = 512;
export const LEAD_GENIE_ROLE = 1024;

export const CUSTOMER_ROLE_PARAM = 'user';
export const PROVIDER_ROLE_PARAM = 'provider';
export const AGENT_ROLE_PARAM = 'agent';
export const PLATFORM_ROLE_PARAM = 'admin';

export const roleNames = {
  [PROVIDER_OD_ROLE]: 'Housing Partner',
  [AGENT_ND_ROLE]: 'Partner Agent'
};
